import { Text, VStack } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

function Address(): React.ReactElement {
  const { t } = useTranslation('common')

  return (
    <VStack
      spacing="0"
      sx={{
        alignItems: 'stretch',
        textAlign: {
          base: 'center',
          sm: 'left',
        },
      }}
    >
      <Text
        sx={{
          fontWeight: 'medium',
          fontSize: 'xl',
        }}
      >
        {t('contact.companyName')}
      </Text>
      <Text
        sx={{
          fontSize: 'xl',
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: t('contact.address') }} />
      </Text>
    </VStack>
  )
}

export default Address
